import Logo from "../../Images/logo.jpg";
import { SlSocialInstagram } from "react-icons/sl";
import { TfiEmail } from "react-icons/tfi";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="grid grid-cols-3 bg-black h-40 text-white bottom-0 relative">
        <div className="py-10 md:px-10 px-3">
          <Link to="/">
            <img className="h-12 w-20" src={Logo} alt="Logo" />
          </Link>
          <p>slogun</p>
        </div>
        <div className="py-10 md:px-10 px-3 text-center">
          <ul className="inline-flex">
            <li className="inline-block mx-2 md:mx-6">
              <Link>
                <SlSocialInstagram className="md:w-8 md:h-8 w-5 h-5" />
              </Link>
            </li>
            <li className="inline-block">
              <Link>
                <TfiEmail className="md:w-8 md:h-8 w-5 h-5" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="py-10 md:px-10 px-3 float-right text-center text-base md:text-lg">
          <p>©2024 MasterShave. All Right Reserve</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
