import img1 from "../../../../Images/FREDOMBODY.jpg";
// import img2 from "../../../../Images/formul3.jpg";
import img2 from "../../../../Images/fhead.jpg";
// import img3 from "../../../../Images/formul2.jpg";
import img3 from "../../../../Images/fbody.jpg";
import img4 from "../../../../Images/FREDOMHEAD.jpg";

const Gallery = () => {
  return (
    <>
      <div className="lg:w-8/12 w-10/12 mx-auto">
        <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
          <div className="basis-3/4 ">
            <img
              src={img1}
              alt="gallery1"
              className="rounded-xl w-full md:rounded-3xl"
            />
          </div>
          <div className="basis-[23%] rounded-3xl">
            <img
              src={img4}
              alt="gallery4"
              className="rounded-xl md:rounded-3xl w-full"
            />
          </div>
        </div>
        <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
          <div className="basis-[23%] rounded-3xl mb-5">
            <img
              src={img2}
              alt="gallery2"
              className="rounded-xl md:rounded-3xl w-full"
            />
          </div>
          <div className="basis-3/4">
            <img
              src={img3}
              alt="gallery3"
              className="rounded-xl w-full md:rounded-3xl"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
