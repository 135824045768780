import React from "react";
import { useState } from "react";
import "../../../App/App.css";
import bgzylone from "../../../../Images/ZYLONEHome.png";
import bginova from "../../../../Images/INOVABANNER.png";
import { useEffect } from "react";

const Parallaxbg = () => {
  const sliderImages = [bgzylone, bginova];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderImages.length);
    }, 4000);
    return () => clearInterval(intervalId);
  }, [sliderImages.length]);
  return (
    <>
      <div
        style={{ backgroundImage: `url(${sliderImages[currentSlide]})` }}
        className="parallax z-0 marquee"
      >
        <div className="bg-indigo-600 bg-opacity-0 text-black p-8 text-center absolute w-full px-auto py-[15%]">
          <p className="text-4xl md:text-6xl font-semibold text-white italic">
            A FRESH NEW SHAVE
          </p>
        </div>
      </div>
    </>
  );
};

export default Parallaxbg;
