import f1front from "../../../../Images/f1front.png";
import freefront from "../../../../Images/freefront.png";
import r2front from "../../../../Images/r2FRONT.png";
import r3front from "../../../../Images/r3front.png";
import invfront from "../../../../Images/invfront.png";
import rpbluefront from "../../../../Images/rpblue-front.png";
import zyfront from "../../../../Images/zyfront.png";
import s2front from "../../../../Images/s2front.png";
import s3front from "../../../../Images/s3front.png";
import solo from "../../../../Images/soloFRONT.png";
let item = [
  {
    id: "1",
    name: "Formul1",
    Image: f1front,
  },
  {
    id: "2",
    name: "FreeDom",
    Image: freefront,
  },
  {
    id: "3",
    name: "Zylone",
    Image: zyfront,
  },
  {
    id: "4",
    name: "Inova 6",
    Image: invfront,
  },
  {
    id: "5",
    name: "Race 3",
    Image: r3front,
  },
  {
    id: "6",
    name: "Sense 3",
    Image: s3front,
  },
  {
    id: "7",
    name: "Rally 2 Plus",
    Image: rpbluefront,
  },
  {
    id: "8",
    name: "Sense 2",
    Image: s2front,
  },
  {
    id: "9",
    name: "Rally 2",
    Image: r2front,
  },
  {
    id: "10",
    name: "Solo 1",
    Image: solo,
  },
];

export default item;
