import banner from "../../../Images/product.JPG";
import men from "../../../Images/F1BANNER.png";
import women from "../../../Images/FREDOM BANNER.png";

let Category = [
  {
    id: "A",
    name: "women",
    banner: women,
    desc: "A description about women products",
  },
  {
    id: "B",
    name: "men",
    banner: men,
    desc: "A description about men products",
  },
];
export default Category;
