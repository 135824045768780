import { useParams } from "react-router-dom";
import Footer from "../../../Footer/Footer";
// import NavBar from "../../../Header/Navbar";
import ProductData from "../ProductData";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../styles.css";
import { useState } from "react";
const ProductDetail = () => {
  const { productId } = useParams();
  const thisProduct = ProductData.find((prod) => prod.id === productId);
  const [selectedImage, setSelectedImage] = useState(thisProduct.coverimg);
  return (
    <>
      {/* <NavBar /> */}
      <div className="w-9/12 mx-auto hidden lg:grid grid-cols-3">
        <div className="lg:col-span-2 bg-gray-100 m-5 md:col-start-1 md:col-end-4 md:min-h-96">
          <p>{thisProduct.desc}</p>
        </div>
        <div className="basis-2/5 m-5">
          <div className="p-6 flex bg-gray-100">
            <img
              src={selectedImage}
              alt={thisProduct.name}
              className="w-4/5 mx-auto"
            />
          </div>
          <div className="grid grid-cols-2 w-full mt-4 gap-5">
            {thisProduct.Image.map((image, index) => (
              <div
                className="w-full p-4 bg-gray-100"
                key={index}
                onClick={() => setSelectedImage(image)}
              >
                <img className="" src={image}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="lg:hidden grid w-10/12 mx-auto my-7 p-5 pt-9">
        <Swiper
          style={
            {
              // "--swiper-navigation-color": "#fff",
              // "--swiper-pagination-color": "#fff",
            }
          }
          slidesperView={2}
          loop={true}
          spaceBetween={10}
          pagination={true}
          navigation={true}
          modules={[FreeMode, Navigation, Pagination]}
          className="mySwiper2 p-10 bg-gray-100"
        >
          {thisProduct.Image.map((image, index) => (
            <SwiperSlide className="px-6" xkey={index}>
              <img
                className="w-10/12 rounded-2xl pb-9"
                src={image}
                onClick={() => setSelectedImage(image)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* 
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper mythumSwiper my-7 bg-gray-100"
        >
          {thisProduct.Image.map((image, index) => (
            <SwiperSlide key={index} className="gap-1">
              <img className="w-full rounded-2xl py-3" src={image} />
            </SwiperSlide>
          ))}
        </Swiper> */}
        <div className=" bg-gray-100 my-6 p-5 md:min-h-96">
          <p>{thisProduct.desc}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ProductDetail;
